.App {
  text-align: center;
}

.App-logo {
  /*animation: App-logo-spin infinite 5s linear;*/
  /*animation: animated_div 5s 1;*/
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

.nudge {
  animation: 
    pulse 3s ease infinite alternate, 
    nudge 5s linear infinite alternate;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes animated_div {
  0% {
    transform: rotate(0deg);
    left: 0px;
  }
  25% {
    transform: rotate(20deg);
    left: 0px;
  }
  50% {
    transform: rotate(0deg);
    left: 500px;
  }
  55% {
    transform: rotate(0deg);
    left: 500px;
  }
  70% {
    transform: rotate(0deg);
    left: 500px;
    background: #1ec7e6;
  }
  100% {
    transform: rotate(-360deg);
    left: 0px;
  }
}

@-webkit-keyframes animated_div {
  0% {
    -webkit-transform: rotate(0deg);
    left: 0px;
  }
  25% {
    -webkit-transform: rotate(20deg);
    left: 0px;
  }
  50% {
    -webkit-transform: rotate(0deg);
    left: 500px;
  }
  55% {
    -webkit-transform: rotate(0deg);
    left: 500px;
  }
  70% {
    -webkit-transform: rotate(0deg);
    left: 500px;
    background: #1ec7e6;
  }
  100% {
    -webkit-transform: rotate(-360deg);
    left: 0px;
  }
}

@-moz-keyframes animated_div {
  0% {
    -moz-transform: rotate(0deg);
    left: 0px;
  }
  25% {
    -moz-transform: rotate(20deg);
    left: 0px;
  }
  50% {
    -moz-transform: rotate(0deg);
    left: 500px;
  }
  55% {
    -moz-transform: rotate(0deg);
    left: 500px;
  }
  70% {
    -moz-transform: rotate(0deg);
    left: 500px;
    background: #1ec7e6;
  }
  100% {
    -moz-transform: rotate(-360deg);
    left: 0px;
  }
}

@-o-keyframes animated_div {
  0% {
    transform: rotate(0deg);
    left: 0px;
  }
  25% {
    transform: rotate(20deg);
    left: 0px;
  }
  50% {
    transform: rotate(0deg);
    left: 500px;
  }
  55% {
    transform: rotate(0deg);
    left: 500px;
  }
  70% {
    transform: rotate(0deg);
    left: 500px;
    background: #1ec7e6;
  }
  100% {
    transform: rotate(-360deg);
    left: 0px;
  }
}
